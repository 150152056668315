<template>
    <div class="bg-white fixed top-0 left-0 w-full h-20">
        <div class="fixed top-0 left-0 p-2">
            <router-link :to="{ name: 'HomeView' }">
                <img src="logo.svg" alt="Logo" class="h-16">
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header_component',
    data() {
    },
    methods: {
    }
};

</script>

<style>
body {
    margin: 0;
    height: 100%;
}

nav a {
    font-weight: bold;
    color: #f4f4f4;
}
</style>
