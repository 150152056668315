<template>
  <Header />
  <div>
    <router-view />
  </div>
</template>



<script>
import Header from './layouts/header.vue'
export default {
  components: {
    Header
  }
};

</script>

<style>
body {
  margin: 0;
  height: 100%;
}

nav a {
  font-weight: bold;
  color: #f4f4f4;
}
</style>
