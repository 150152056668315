import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import './index.css'
import 'vue-select/dist/vue-select.css';
import VueCookies from 'vue3-cookies'
import vSelect from 'vue-select'


const app = createApp(App)

app.use(VueCookies).use(router).use(store).use(VueAxios, axios).mount('#app')
app.component('v-select', vSelect)
