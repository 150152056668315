import axiosinstance from '../../../services/axiosInstance'

export const actions = {
  async getAllIdp({ commit }) {
    axiosinstance
      .get('/idp-list')
      .then((response) => {
        if (response.data.code === 200) {
          commit('allIdpsData', response.data.idps.map(x => x.idp))
        } else {
          commit('allIdpsData', [])
        }
      })
      .catch((err) => {
        commit('allIdpsData', [])
        console.log(err)
      })
  }
}
