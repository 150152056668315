<template>
    <div class="mt-32">
        <div>
            <h1 class="text-3xl pl-10">Welcome to the Home page.</h1>
        </div>
        <br>
        <br>
        <br>

        <div v-if="showDiv">
            <p class="text-3xl pl-10">Option to remove cookies</p>
            <button class="border border-l-neutral-700 bg-slate-200 ml-10" @click="remove()">Remove</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home_view',
    data() {
        return {
            showDiv: this.$cookies.get('stick') || false
        }
    }, 
    methods: {
        remove() {
            this.$cookies.remove('stick')
            alert('cookies removed success')
            this.showDiv = false
        }
    }
};

</script>

<style>
body {
    margin: 0;
    height: 100%;
}

nav a {
    font-weight: bold;
    color: #f4f4f4;
}
</style>
