import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/login.vue'
import HomeView from '../views/homeView.vue'
import dataRedir from '../views/dataRedir.vue'

const routes = [
  {
    path: '/auth',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/r/:passthrough(.*)',
    name: 'Authorization',
    component: dataRedir
  }
]

const history = createWebHistory(process.env.BASE_URL)
const router = createRouter({ history, routes })

export default router
