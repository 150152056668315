import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import userSearch from './modules/user'

const debug = process.env.NODE_ENV !== 'production'
export default createStore({
  modules: {
    userSearch
  },
  strict: debug,
  // eslint-disable-next-line multiline-ternary
  plugins: debug
    ? [createLogger(), createPersistedState()]
    : [createPersistedState()]
})
