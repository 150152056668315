<template>
  <div
    v-if="hasError"
    class="h-screen flex justify-center items-center bg-[#002349]"
  >
    <div
      class="w-full md:w-min h-max font-opensans py-5 px-3 bg-[#880808] md:p-10 border-2 rounded-lg mx-3 md:mx-0 z-10 shadow-xl"
    >
      <span>{{ error }}</span>
    </div>
  </div>

  <div class="h-screen flex justify-center items-center bg-[#002349]">
    <div
      class="w-full md:w-min h-max font-opensans py-5 px-3 bg-[#F4F4F4] md:p-10 border-2 rounded-lg mx-3 md:mx-0 z-10 shadow-xl"
    >
      <span
        >Login an Ihrem gewählten IDP wird durchgeführt. Bitte haben Sie einen
        moment Geduld.</span
      >
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import axiosinstance from '../services/axiosInstance.js'
import { useRoute } from 'vue-router'
import axios from 'axios'
import * as oauth from 'oauth4webapi'
import cookie from 'cookie'

const scrollContainer = ref(null)

async function processOIDC({
  as,
  client,
  currentUrl,
  redirect_uri,
  code_verifier
}) {
  currentUrl = new URL(currentUrl)
  const params = oauth.validateAuthResponse(as, client, currentUrl)
  if (oauth.isOAuth2Error(params)) {
    console.error('Error Response', params)
    throw new Error(`${params.error} ${params.error_description}`) // Handle OAuth 2.0 redirect error
  }

  const response = await oauth.authorizationCodeGrantRequest(
    as,
    client,
    params,
    redirect_uri,
    code_verifier
  )

  let challenges = undefined
  if ((challenges = oauth.parseWwwAuthenticateChallenges(response))) {
    for (const challenge of challenges) {
      console.error('WWW-Authenticate Challenge', challenge)
    }
    throw new Error('WWW-Authenticate Challenge') // Handle WWW-Authenticate Challenges as needed
  }

  const result = await oauth.processAuthorizationCodeOpenIDResponse(
    as,
    client,
    response
  )
  if (oauth.isOAuth2Error(result)) {
    console.error('Error Response', result)
    throw new Error(`${result.error} ${result.error_description}`) // Handle OAuth 2.0 response body error
  }

  return result
}

export default {
  name: 'Authorization_View',
  components: {},
  data() {
    return {
      hasError: false,
      error: '',
      searchText: '',
      searchEmail: '',
      openDropDown: false,
      pointer: null,
      userEmailSearched: [],
      userIdpSearched: [],
      emailSuggestionBox: true,
      idpSuggestionBox: false
    }
  },
  created() {
    //console.log('hello', route.params)
    const route = useRoute()

    let mr = useRoute()
    let uid
    let res

    const getIt = async (r) => {
      r = r ? `&result=${btoa(JSON.stringify(r))}` : ''

      if (!res) {
        res = await axiosinstance.get(`/oidc/continue`).catch((e) => {
          this.hasError = true
          this.error = e.message

          throw e
        })
      }

      axiosinstance
        .get(`${res.data.callbackUrl}${window.location.search}${r}`)
        .catch((e) => {
          this.hasError = true
          this.error = e.message

          throw e
        })
        .then(async (x) => {
          if (x.data.code === 303) {
            const c = cookie.parse(document.cookie)

            document.cookie = cookie.serialize('stick', c.hang, {
              domain: window.COOKIE_DOMAIN,
              path: '/',
              maxAge: 365 * 24 * 3600 * 5
            })
            window.location = x.data.uri
          } else if (x.data.code === 206) {
            if (x.data.result.final === false && x.data.result.i === 'pkce') {
              const res = await processOIDC(x.data.result.request).catch(
                (e) => {
                  this.hasError = true
                  this.error = e.message

                  throw e
                }
              )
              getIt(res)
            }
          } else {
            console.log(x)
          }
        })
    }

    getIt()
  },
  methods: {},
  computed: {},
  mounted() {},
  watch: {}
}
</script>
